import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss'],
})
export class WaitingComponent implements OnInit {
  @Input() size = 55;
  @Input() fullscreen = false;
  @Input() opaque = false;

  constructor() {}

  ngOnInit() {}
}
