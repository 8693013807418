import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineBreak',
})
export class LineBreakPipe implements PipeTransform {
  /**
   * @usage
   *
   * .ts
   * ```
   * const msg = 'upper text \n new line text';
   * ```
   *
   * .html
   * ```
   * <p [innerHTML]="msg | lineBreak"></p>
   * ```
   *
   * output:
   * ```
   * <p>
   *   upper text
   *   <br>
   *   new line text
   * </p>
   * ```
   */
  transform(value: string = '', args?: any): any {
    return value.replace(/\n/g, '<br/>');
  }
}
