import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  transform(date, exportFormat: string) {
    if (!date) return '';
    const isUnix = !isNaN(+date) && (date + '').length <= 10;
    date = isUnix ? date * 1000 : date;
    // const serverTimeZoneOffset = 4 * 3600 * 1000;
    // const newDate1 = +new Date(date) + (new Date(date).getTimezoneOffset() * 60 - serverTimeZoneOffset); // Timezone
    return moment(new Date(date)).format(exportFormat);
  }
}
