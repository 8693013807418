import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringNormalizer',
})
export class StringNormalizerPipe implements PipeTransform {
  /**
   * @description
   * String like "some_randomCamelCased_string" will return as "some random camel cased string"
   *
   * @param str<string>
   */
  transform(str: string): any {
    return (
      str &&
      str
        .split('_')
        .join(' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .toLowerCase()
    );
  }
}
